@import url('https://fonts.googleapis.com/css?family=Montserrat:300');

#contactBox{
  padding-top: 40px;
  display: flex;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
}
.wrapper {
  height: 725px;
  width: 460px;
  border: solid;
  background-color: white;
  border-radius: 24px;
}

.header {
  width: 100%;
  height: 32%;
  background-position: center;
  color: black;
  text-align: center;
}


#contactForm {
  flex-direction: column;
  margin: 8px auto;
  padding-top: 25px;
  width: 76%;
}

#contactText,
#contactInput {
  border: solid 1px #cbcbcb;
  border-radius: 6px;
  width: 92%;
  padding: 6px 12px;
  outline: none;
  margin: 4px auto;
}

#contactInput {
  height: 30px;
}

#contactText {
  height: 62px;
}

#send {
  height: 42px;
  width: 60%;
  margin: 24px auto;
  border-radius: 6px;
  border: none;
  color: black;
  font-weight: bold;
  background: linear-gradient(to left bottom, #00b34d, #00b34d);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center
}


.alert {
    margin: auto; display: table;
}
