.card-deck {
    padding-top: 40px;
    padding-bottom: 40px;
}

.homeCard {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

 .homeCard:hover {
   background-color: rgba(251, 251, 251, 0.35);
       transform: scale(1.1);
       overflow:hidden;
 }

 #card2:hover{
   opacity: 0.8;
 }
 #card1:hover{
   opacity:0.8;
 }
 #scheduleButton:hover{
   opacity:0.7;
 }
 #icons{
   display:flex;
   justify-content: center;
 }

#cardIcons{
display: block;
 margin-left: auto;
 margin-right: auto;
}

.servicesCard {
  display: flex;
  max-width: 250px;
  margin:0 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.servicesCard:hover {
  animation: shake 2s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

@media only screen and (max-width: 700px) {
  .servicesCardDeck{
    width: 150%
  }
}
.servicesCardDeck{
  padding-top: 50px;
  display:flex;
  align-items: center;
  justify-content: center;
   flex-direction: row;
}
