.title{
  text-align: center;
  padding-bottom: 30px;
  font-family: sans-serif;
}
.mission{
}
.vector{
}
.content{
  display: flex;
  flex-direction: row;     /* make main axis horizontal (default setting) */
  justify-content: center; /* center items horizontally, in this case */
  align-items: center;     /* center items vertically, in this case */
  overflow: hidden;
}
#vector{
  max-width: 50%;
  max-height: auto;
}
