@import url(https://fonts.googleapis.com/css?family=Montserrat:300);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.navbar{
  padding-top:0;
  padding-bottom:0;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
  overflow: hidden;
}
.nav-pills .nav-link.active {
  border-radius: 13px;
  border: 1px;
    background-color: #00b34d
!important;
}

.carousel {
    border: 1px;
    border-color: #e6e6e6
}

.card-deck {
    padding-top: 40px;
    padding-bottom: 40px;
}

.homeCard {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

 .homeCard:hover {
   background-color: rgba(251, 251, 251, 0.35);
       -webkit-transform: scale(1.1);
               transform: scale(1.1);
       overflow:hidden;
 }

 #card2:hover{
   opacity: 0.8;
 }
 #card1:hover{
   opacity:0.8;
 }
 #scheduleButton:hover{
   opacity:0.7;
 }
 #icons{
   display:-webkit-flex;
   display:flex;
   -webkit-justify-content: center;
           justify-content: center;
 }

#cardIcons{
display: block;
 margin-left: auto;
 margin-right: auto;
}

.servicesCard {
  display: -webkit-flex;
  display: flex;
  max-width: 250px;
  margin:0 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.servicesCard:hover {
  -webkit-animation: shake 2s;
          animation: shake 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

@-webkit-keyframes shake {
  0% { -webkit-transform: translate(1px, 1px) rotate(0deg); transform: translate(1px, 1px) rotate(0deg); }
  10% { -webkit-transform: translate(-1px, -2px) rotate(-1deg); transform: translate(-1px, -2px) rotate(-1deg); }
  20% { -webkit-transform: translate(-3px, 0px) rotate(1deg); transform: translate(-3px, 0px) rotate(1deg); }
  30% { -webkit-transform: translate(3px, 2px) rotate(0deg); transform: translate(3px, 2px) rotate(0deg); }
  40% { -webkit-transform: translate(1px, -1px) rotate(1deg); transform: translate(1px, -1px) rotate(1deg); }
  50% { -webkit-transform: translate(-1px, 2px) rotate(-1deg); transform: translate(-1px, 2px) rotate(-1deg); }
  60% { -webkit-transform: translate(-3px, 1px) rotate(0deg); transform: translate(-3px, 1px) rotate(0deg); }
  70% { -webkit-transform: translate(3px, 1px) rotate(-1deg); transform: translate(3px, 1px) rotate(-1deg); }
  80% { -webkit-transform: translate(-1px, -1px) rotate(1deg); transform: translate(-1px, -1px) rotate(1deg); }
  90% { -webkit-transform: translate(1px, 2px) rotate(0deg); transform: translate(1px, 2px) rotate(0deg); }
  100% { -webkit-transform: translate(1px, -2px) rotate(-1deg); transform: translate(1px, -2px) rotate(-1deg); }
}

@keyframes shake {
  0% { -webkit-transform: translate(1px, 1px) rotate(0deg); transform: translate(1px, 1px) rotate(0deg); }
  10% { -webkit-transform: translate(-1px, -2px) rotate(-1deg); transform: translate(-1px, -2px) rotate(-1deg); }
  20% { -webkit-transform: translate(-3px, 0px) rotate(1deg); transform: translate(-3px, 0px) rotate(1deg); }
  30% { -webkit-transform: translate(3px, 2px) rotate(0deg); transform: translate(3px, 2px) rotate(0deg); }
  40% { -webkit-transform: translate(1px, -1px) rotate(1deg); transform: translate(1px, -1px) rotate(1deg); }
  50% { -webkit-transform: translate(-1px, 2px) rotate(-1deg); transform: translate(-1px, 2px) rotate(-1deg); }
  60% { -webkit-transform: translate(-3px, 1px) rotate(0deg); transform: translate(-3px, 1px) rotate(0deg); }
  70% { -webkit-transform: translate(3px, 1px) rotate(-1deg); transform: translate(3px, 1px) rotate(-1deg); }
  80% { -webkit-transform: translate(-1px, -1px) rotate(1deg); transform: translate(-1px, -1px) rotate(1deg); }
  90% { -webkit-transform: translate(1px, 2px) rotate(0deg); transform: translate(1px, 2px) rotate(0deg); }
  100% { -webkit-transform: translate(1px, -2px) rotate(-1deg); transform: translate(1px, -2px) rotate(-1deg); }
}

@media only screen and (max-width: 700px) {
  .servicesCardDeck{
    width: 150%
  }
}
.servicesCardDeck{
  padding-top: 50px;
  display:-webkit-flex;
  display:flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
   -webkit-flex-direction: row;
           flex-direction: row;
}

.service-button{
}
#buttons{
  padding-top: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
-webkit-flex-direction: column;
        flex-direction: column;
}

#contactBox{
  padding-top: 40px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  font-family: 'Montserrat', sans-serif;
}
.wrapper {
  height: 725px;
  width: 460px;
  border: solid;
  background-color: white;
  border-radius: 24px;
}

.header {
  width: 100%;
  height: 32%;
  background-position: center;
  color: black;
  text-align: center;
}


#contactForm {
  -webkit-flex-direction: column;
          flex-direction: column;
  margin: 8px auto;
  padding-top: 25px;
  width: 76%;
}

#contactText,
#contactInput {
  border: solid 1px #cbcbcb;
  border-radius: 6px;
  width: 92%;
  padding: 6px 12px;
  outline: none;
  margin: 4px auto;
}

#contactInput {
  height: 30px;
}

#contactText {
  height: 62px;
}

#send {
  height: 42px;
  width: 60%;
  margin: 24px auto;
  border-radius: 6px;
  border: none;
  color: black;
  font-weight: bold;
  background: linear-gradient(to left bottom, #00b34d, #00b34d);
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center
}


.alert {
    margin: auto; display: table;
}

.title{
  text-align: center;
  padding-bottom: 30px;
  font-family: sans-serif;
}
.mission{
}
.vector{
}
.content{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;     /* make main axis horizontal (default setting) */
  -webkit-justify-content: center;
          justify-content: center; /* center items horizontally, in this case */
  -webkit-align-items: center;
          align-items: center;     /* center items vertically, in this case */
  overflow: hidden;
}
#vector{
  max-width: 50%;
  max-height: auto;
}

#wrapper{
  display: -webkit-flex;
  display: flex;
  background: rgb(236,236,236);
  background: linear-gradient(90deg, rgba(236,236,236,1) 0%, rgba(255,255,255,1) 48%, rgba(236,236,236,1) 100%)
}
#contact{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-content: center;
          align-content: center;
}

#wrapper-9cd199b9cc5410cd3b1ad21cab2e54d3{
  margin-left: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.clear {
  clear: both;
}

