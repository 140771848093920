#wrapper{
  display: flex;
  background: rgb(236,236,236);
  background: linear-gradient(90deg, rgba(236,236,236,1) 0%, rgba(255,255,255,1) 48%, rgba(236,236,236,1) 100%)
}
#contact{
  display: flex;
  flex-direction: column;
  align-content: center;
}

#wrapper-9cd199b9cc5410cd3b1ad21cab2e54d3{
  margin-left: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.clear {
  clear: both;
}
