.navbar{
  padding-top:0;
  padding-bottom:0;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
  overflow: hidden;
}
.nav-pills .nav-link.active {
  border-radius: 13px;
  border: 1px;
    background-color: #00b34d
!important;
}

.carousel {
    border: 1px;
    border-color: #e6e6e6
}
